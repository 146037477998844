"use strict"

var klass = require("mbx/src/class").class

var ClientRect = require("mbx/src/dom/ClientRect").ClientRect
var Event = require("mbx/src/Event").Event
var EventTarget = require("mbx/src/EventTarget").EventTarget

var offsetchangeevtname = "offsetchange"
var visibilitychangeevtname = "visibilitychange"

var EVT = klass(Event, function(statics){
    var events = Object.create(null)

    return {
        constructor: function(type, dict){
            Event.call(this, type)
            events[this.uid] = Object.create(null)

            events[this.uid].coverage = dict.coverage
            events[this.uid].prevCoverage = dict.prevCoverage
            events[this.uid].delta = dict.offset - dict.prevOffset
            events[this.uid].node = dict.node
            events[this.uid].offset = dict.offset
            events[this.uid].prevOffset = dict.prevOffset
            events[this.uid].visibility = dict.visibility
            events[this.uid].prevVisibility = dict.prevVisibility
            events[this.uid].internalScroll = dict.internalScroll
        }
      , coverage: { enumerable: true,
            get: function(){
                return events[this.uid].coverage
            }
        }
      , prevCoverage: {
            get: function(){
                return events[this.uid].prevCoverage
            }
        }
      , delta: { enumerable: true,
            get: function(){
                return events[this.uid].delta
            }
        }
      , internalScroll: { enumerable: true,
            get: function(){
                return events[this.uid].internalScroll
            }
        }
      , node: { enumerable: true,
            get: function(){
                return events[this.uid].node
            }
        }
      , offset: { enumerable: true,
            get: function(){
                return events[this.uid].offset
            }
        }
      , prevOffset: { enumerable: true,
            get: function(){
                return events[this.uid].prevOffset
            }
        }
      , visibility: { enumerable: true,
            get: function(){
                return events[this.uid].visibility
            }
        }
      , prevVisibility: { enumerable: true,
            get: function(){
                return events[this.uid].prevVisibility
            }
        }
    }
})

module.exports.OffsetChangeEVT = klass(EVT, {
    constructor: function(dict){
        EVT.call(this, offsetchangeevtname ,dict)
    }
})

module.exports.VisibilityChangeEVT = klass(EVT, {
    constructor: function(dict){
        EVT.call(this, visibilitychangeevtname ,dict)
    }
})

module.exports.ScrollWatcher = klass(EventTarget, function(statics){
    Object.defineProperties(statics, {
        OFFSETCHANGE_EVT: { enumerable: true,
            value: offsetchangeevtname
        }
      , VISIBILITYCHANGE_EVT: { enumerable: true,
          value: visibilitychangeevtname
      }
    })

    var watchers = Object.create(null)
    var watched = []

    function onevent(e){
        var watchers = [].concat(watched)

        while ( watchers.length )
          void function(watcher){
              watcher.dispatchEvent("computerequest", e)
          }(watchers.shift())
    }

    window.addEventListener("scroll", onevent)
    window.addEventListener("resize", onevent)
    window.addEventListener("orientationchange", onevent)

    if ( window.MutationObserver )
      void function(observer, timer){
          observer = new MutationObserver(function(mutations){
              cancelAnimationFrame(timer)
              timer = requestAnimationFrame(onevent)
          })
          observer.observe(document.documentElement, { childList: true, subtree: true })
      }()

    return {
        constructor: function(node, onpurge, oncomputerequest){
            watchers[this.uid] = Object.create(null)
            watchers[this.uid].clientRect = new ClientRect(node)
            watchers[this.uid].node = node && node.nodeType === Node.ELEMENT_NODE ? node : document.createElement("div")
            watchers[this.uid].offset = 0

            oncomputerequest = function(){
                if ( document.documentElement.compareDocumentPosition(watchers[this.uid].node) & Node.DOCUMENT_POSITION_DISCONNECTED )
                  return

                watchers[this.uid].clientRect.compute(document.documentElement, function(err, matrix, offset, height, visibleheight, screenheight, coverage, edata){
                    if ( err ) {
                        console.error(err)
                        return
                    }


                    offset = matrix.N.y
                    height = matrix.height
                    screenheight = window.innerHeight

                    visibleheight = (offset > screenheight) || ( offset < -height) ? 0
                                  : Math.min(height, screenheight, screenheight-offset, height+offset)
                    coverage = visibleheight/height*100

                    edata = {
                        coverage: coverage
                      , prevCoverage: watchers[this.uid].coverage
                      , node: watchers[this.uid].node
                      , offset: offset
                      , prevOffset: watchers[this.uid].offset
                      , visibility: !!Math.ceil(coverage)
                      , prevVisibility: !!watchers[this.uid].visibility
                      , internalScroll: !watchers[this.uid].visibility ? 0 : window.innerHeight - watchers[this.uid].offset
                    }

                    watchers[this.uid].coverage = edata.coverage
                    watchers[this.uid].offset = offset
                    watchers[this.uid].visibility = edata.visibility
                    watchers[this.uid].internalScroll = edata.internalScroll

                    if ( edata.prevVisibility !== edata.visibility )
                        this.dispatchEvent(new module.exports.VisibilityChangeEVT(edata))
                    this.dispatchEvent(new module.exports.OffsetChangeEVT(edata))
                }.bind(this))
            }.bind(this)

            onpurge = function(){
                this.removeEventListener("computerequest", oncomputerequest)
                this.removeEventListener("purge", onpurge)
            }.bind(this)

            oncomputerequest()
            this.addEventListener("computerequest", oncomputerequest)
            this.addEventListener("purge", onpurge)
            this.watch()
        }
      , compute: { enumerable: true,
            value: function(){
                this.dispatchEvent("computerequest")
            }
        }
      , coverage: { enumerable: true,
            get: function(){
                return watchers[this.uid].coverage
            }
        }
      , offset: { enumerable: true,
            get: function(){
                if ( document.documentElement.compareDocumentPosition(watchers[this.uid].node) & Node.DOCUMENT_POSITION_DISCONNECTED )
                  return null
                return watchers[this.uid].offset
            }
        }
      , internalScroll: {
            get: function(){
                return watchers[this.uid].internalScroll
            }
        }
      , node: { enumerable: true,
            get: function(){
                return watchers[this.uid].node
            }
        }
      , stop: { enumerable: true,
            value: function(idx){
                if ( idx = watched.indexOf(this), idx > -1 )
                  watched.splice(idx, 1)
            }
        }
      , visibility: { enumerable: true,
            get: function(){
                return watchers[this.uid].visibility
            }
        }
      , watch: { enumerable: true,
            value: function(){
                if ( watched.indexOf(this) == -1 )
                  watched.push(this)
            }
        }
      , purge: { enumerable: true,
            value: function(){
                this.stop()
                this.dispatchEvent("purge")
                return EventTarget.prototype.purge.apply(this, arguments)
            }
        }
    }
})
